import { mapState } from 'vuex';
import { getDirectJuniorsList } from '@/service/enroll-activity/index';

export default{
    data(){
        return{
            loading: true,
            finished: false,
            offset: 1,
            keyword:'',
            directJuniorsList: []
        }
    },
    computed:{
        ...mapState({
            user: state => state.user.user
        }),
    },
    mounted(){
        this.getDirectJuniors()
    },
    methods:{
        // 查询直属下级
        async getDirectJuniors(){
            let { offset, keyword, user, directJuniorsList } = this;
            let res = await getDirectJuniorsList({
                systemId: 1,
                keyword,
                seniorId: user.id, 
                limit: 10,
                curPage: offset,
            })

            this.loading = false
            this.directJuniorsList = [ ...directJuniorsList, ...res.records ]
            if(offset == 1) this.directJuniorsList.unshift({ user })
            if (res.records.length < 10) this.finished = true
        },
        // 搜索
        onInputSearch(){
            this.offset = 1
            this.directJuniorsList = []
            this.getDirectJuniors()
        },
        // 加载
        onLoad(){
            this.offset ++
            this.getDirectJuniors()
        },
        onBtn({ id, name, identity, phone }){
            this.$bus.$emit('agentInfo', { id, name, identity, phone })
            this.$router.go(-1);
        }
    }
}